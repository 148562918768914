<template>
  <div v-cloak v-if="show" id="CheckOut" style="text-align: center">
    <v-dialog
      class="panel dialogBox"
      max-width="800px"
      v-model="showModalFees"
      persistent
    >
      <v-card class="pad fees-card">
        <fees
          data-testid="feesPanel"
          :fees="fees"
          :readonly="mode === 'edit'"
          :overrideFeesProp="overrideFees"
          @overrideFees="overrideFees = $event"
          @overrideIsValid="overrideIsValid = $event"
        />
      </v-card>
    </v-dialog>
    <div v-if="showModalFees" class="fixedBottom mb-2">
      <v-btn
        id="override-cancel"
        v-if="overrideFees"
        @click="
          overrideFees = false;
          showModalFees = false;
        "
        color="red"
        >Cancel Override</v-btn
      >
      <v-btn
        :disabled="!overrideIsValid"
        @click="showModalFees = false"
        color="green"
        class="finishEditFees"
        >Finish</v-btn
      >
    </div>

    <v-dialog
      overlay-color="white"
      overlay-opacity="100"
      v-model="showModalPreview"
    >
      <div>
        <v-btn large @click="showModalPreview = false" icon>
          <v-icon id="modalCloseButton" color="error">close</v-icon>
        </v-btn>
      </div>
      <div class="panel">
        <displayVehicleData
          :processObj="JSON.parse(JSON.stringify(this.$store.getters.editObj))"
          :readOnly="true"
        ></displayVehicleData>
      </div>
    </v-dialog>

    <div class="mainBox">
      <div>
        <h1 v-if="mode === 'transaction'">Check Out</h1>

        <v-btn v-if="mode === 'transaction'" @click="scanModalIsShown = true">
          <span>Scan documents</span>
        </v-btn>
        <div class="main-cards">
          <v-card width="100%" elevation="24">
            <v-card-title>
              <h2>Fee Summary</h2>
            </v-card-title>

            <hr />

            <v-card-subtitle v-if="mode === 'transaction'">
              <a @click="showModalPreview = true">Preview Transaction</a>
            </v-card-subtitle>

            <v-card-text>
              <div>
                <div>
                  <div v-for="key in sortedFees" :key="key">
                    <template v-if="['Mail Fee', 'Organ Donor'].includes(key)">
                      <bis-finance-input
                        v-if="fees[findFeeIndex(key)].isEditable"
                        v-model="fees[findFeeIndex(key)].feeAmount"
                        outlined
                        :label="key"
                      />
                      <v-select
                        v-else
                        :label="key"
                        :items="fees[findFeeIndex(key)].amountOptions"
                        v-model.number="fees[findFeeIndex(key)].feeAmount"
                        outlined
                      />
                    </template>
                    <v-text-field
                      v-else
                      :value="'$' + parseFloat(categorizedFees[key]).toFixed(2)"
                      disabled
                      outlined
                      :label="key"
                    />
                  </div>
                </div>
                <hr />
                <br />
                <div>
                  <v-text-field
                    label="Total"
                    :value="'$' + nonCCTotal.toFixed(2)"
                    readonly
                    placeholder="0.00"
                    filled
                    outlined
                  />

                  <v-text-field
                    label="CC Total"
                    :value="'$' + ccTotal.toFixed(2)"
                    readonly
                    placeholder="0.00"
                    filled
                    outlined
                  />
                </div>
              </div>
              <a @click="showModalFees = true"> Edit Fees </a>
            </v-card-text>
          </v-card>

          <v-card width="100%" elevation="24">
            <v-card-title>
              <h2>Payment</h2>
            </v-card-title>
            <hr />
            <v-card-text>
              <bis-finance-input
                label="Cash Amount"
                v-model="cash.amount"
                outlined
              >
                <template v-slot:append-outer>
                  <v-btn
                    dense
                    color="blue"
                    @click="
                      cash.amount = parseFloat(nonCCTotal).toFixed(2);
                      check.amount = parseFloat(0).toFixed(2);
                      check.check.checkno = '';
                    "
                  >
                    <span class="white-text">Cash Only</span>
                  </v-btn>
                </template>
              </bis-finance-input>

              <bis-finance-input
                label="Check Amount"
                v-model="check.amount"
                outlined
              >
                <template v-slot:append-outer>
                  <v-btn
                    dense
                    color="blue"
                    @click="
                      check.amount = parseFloat(nonCCTotal).toFixed(2);
                      cash.amount = parseFloat(0).toFixed(2);
                    "
                  >
                    <span class="white-text">Check Only</span>
                  </v-btn>
                </template>
              </bis-finance-input>

              <v-text-field
                label="Check Number"
                v-model="check.check.checkno"
                outlined
              />

              <bis-finance-input
                label="CC Amount"
                v-model="creditTotal"
                filled
                readonly
                outlined
              />

              <template
                v-if="
                  remainingAmount < 0 && cash.amount > 0 && creditTotal == 0
                "
              >
                Change Due: ${{ (remainingAmount * -1).toFixed(2) }}
              </template>

              <div
                v-if="
                  mode === 'edit' &&
                  originalCardAmount !== 0 &&
                  card.amount > originalCardAmount
                "
                class="red-text"
              >
                Credit Card amount cannot be increased from the original amount
                of ${{ originalCardAmount.toFixed(2) }}
              </div>
              <template v-else>
                <div
                  v-if="originalCardAmount === 0"
                  v-show="hubAppIsResponsive"
                  class="manual-card-option"
                >
                  <template v-if="!showPaymentFrame">
                    <a @click="setupManualCardPayment">Manual Card Input</a>
                  </template>
                  <template v-else-if="!isCharging">
                    <a @click="closePaymentFrame">Close Card Input</a>
                  </template>
                </div>
                <template v-if="isCharging">
                  <v-btn color="red" @click="cancel()">Cancel</v-btn>
                </template>
                <div
                  v-show="hubAppIsResponsive"
                  v-else-if="showPaymentFrame"
                  id="iframeDiv"
                >
                  <iframe
                    data-testid="paymentFrame"
                    id="paymentFrame"
                    v-bind="hmacFieldString"
                  ></iframe>
                </div>
                <template v-else-if="!showPaymentFrame">
                  <template>
                    <v-btn
                      data-testid="checkoutButton"
                      :disabled="transactionIsDisabled"
                      :loading="transactionIsPending"
                      color="green"
                      @click="chargeCard()"
                    >
                      <span class="white-text">{{
                        mode === "transaction" ? "Check Out" : "Edit"
                      }}</span>
                    </v-btn>
                    <br />
                  </template>
                </template>
                <span v-if="!hubAppIsResponsive" class="red-text">
                  The Hub App is not connected. Can not check out.<br />
                </span>
              </template>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>

    <scanModal
      :show="scanModalIsShown"
      @close="handleScanClose()"
      @show="scanModalIsShown = true"
      @submitClicked="handleScan"
    />

    <div v-if="mode == 'transaction'" class="floating-buttons">
      <v-btn color="red" @click="goBackKeepEdits()">
        <span class="white-text">Back</span>
      </v-btn>
    </div>

    <v-snackbar
      v-model="isCharging"
      :bottom="true"
      color="info"
      :left="true"
      timeout="-1"
    >
      {{ cardActionMessage }}
    </v-snackbar>

    <v-overlay absolute :value="transactionIsComplete" />
    <SignaturePad
      :show="showSignaturePad"
      :resolve="signatureResolve"
      :cardReaderType="cardReaderType"
      :ptrannum="card.card.ptrannum"
    ></SignaturePad>
  </div>
</template>
<script>
/*global IsSigWebInstalled ,SetTabletState,GetTabletState*/

// components
import displayVehicleData from "@/components/nonPageComponents/DisplayVehicleData";
import scanModal from "@/components/nonPageComponents/scanModal";
import feesComponent from "@/components/vehicleDisplayComponents/fees";
import bisFinanceInput from "@/components/bisFinanceInput";
import SignaturePad from "@/components/nonPageComponents/SignaturePad";

// mixins
import transaction from "@/mixins/transaction.mixin.js";
import feesMixin from "@/mixins/fees.mixin";
import date from "@/mixins/date.mixin";
import { mapGetters, mapActions } from "vuex";

function UnitermException(errorCode, message) {
  this.errorCode = errorCode;
  this.message = message;
}
//class
import { TransactionRequest } from "@/classes/TransactionRequest";
import { formatErrorResponse } from "@/assets/js/format.js";

export default {
  name: "CheckOut",
  mixins: [transaction, feesMixin, date],
  components: {
    displayVehicleData,
    scanModal,
    fees: feesComponent,
    bisFinanceInput,
    SignaturePad
  },
  props: {
    // mode can be transaction or edit to control whether the submission action creates a transaction, or emits to the parent when the transactionRequest has been updated so the parent can submit the edit. Ideally the post-processing on the model done when submitting for an edit will be done reactively in the future allowing us to remove the emit and submit button. Instead the parent can safely submit the edit at any time since the model would be updated on user input.
    mode: {
      type: String,
      default: "transaction"
    },
    transactionRequest: {
      type: Object,
      default: null
    },
    isEditTrans: { type: Boolean, default: false }
  },
  data() {
    const transObj = this.transactionRequest || this.$store.getters.transObj;
    if (!transObj.payments) transObj.payments = [];

    // find existing tenders for each type
    let cash = transObj.payments.find(tender => {
      return tender.tenderType === "Cash";
    });
    if (!cash) {
      cash = { tenderType: "Cash", amount: parseFloat(0).toFixed(2) };
    }
    let check = transObj.payments.find(tender => {
      return tender.tenderType === "Check";
    });
    if (!check) {
      check = {
        tenderType: "Check",
        amount: 0,
        check: { checkno: "" }
      };
    }
    let card = transObj.payments.find(tender => {
      return tender.tenderType === "CreditCard";
    });
    if (!card) {
      card = { tenderType: "CreditCard", amount: 0 };
    }
    if (!this._.has(card, "card")) this._.assign(card, { card: {} });
    let change = transObj.payments.find(tender => {
      return tender.tenderType === "Change";
    });
    if (!change) {
      change = { tenderType: "Change", amount: 0 };
    }

    return {
      transactionComments: undefined,
      showCloseScan: false,
      scanModalIsShown: false,
      hubAppIsResponsive: null,
      transObj: transObj,
      fees: transObj.transaction.fees,
      show: true,
      transactionType: this.$store.getters.transactionType,
      showModalFees: false,
      showModalPreview: false,
      tenderAmount: 0,
      cardResponse: {},
      user: this.$store.getters.userObject,
      creditTotal: 0,
      creditFeesResp: [],
      creditLoading: false,
      creditTimeoutHolder: null,
      showPaymentFrame: false,
      hmacFieldString: {},
      storedTicket: "",
      ccTotal: 0,
      isCharging: false,
      cardReaderType: "hid",
      cardReaderHasKeypad: false,
      cardReaderHasSignatureSupport: false,
      customerManualEntry: false,
      cardActionMessage: "Waiting On Customer",
      cardStatusMap: {
        default: "Waiting On Customer",
        deviceLoading: "Waiting for device",
        CARD_IST: "Waiting for Customer to Insert, Swipe or Tap Card",
        CARD_IS: "Waiting for Customer to Insert Or Swipe Card",
        CARD_I: "Waiting for Customer to Insert Card",
        CARD_S: "Waiting for Customer to Swipe Card",
        CANCEL: "Cancelling. Please wait...",
        REMOVECARD: "Please Remove Card",
        BAD_INSERT: "Bad Insert. Please try again",
        CHIP_CARD_SWIPED: "Chip card swiped. Please Insert Card",
        BAD_SWIOE: "Bad swipe. Please try again",
        MONETRA: "Preparing Device",
        DEVICEOPEN: "Preparing Device",
        SIGNATURE: "Waiting for Customer Signature",
        KEYED: "Waiting for Customer Input"
      },
      underCoverFees: [],
      transactionIsComplete: false,
      overrideIsValid: true,
      overrideFees: false,
      transactionResponse: null,
      transactionIsPending: null,
      cash: cash,
      check: check,
      card: card,
      change: change,
      originalCardAmount: card.amount, // card amount before modifications (on an edit this will be the previous transaction's card amount)
      // Monetra response codes that use Monetra verbiage for error messages
      monetraVerbiageWhitelist: [
        "INT_SUCCESS",
        "DATA_ACCOUNT",
        "DATA_AMOUNT",
        "DATA_EXPDATE",
        "DATA_TRACKDATA",
        "SETUP_CARDTYPE"
      ],
      showSignaturePad: false,
      signatureResolve: null,
      checkDeviceStatus: false,
      clerkCanceled: false
    };
  },
  watch: {
    commentsWithOverride() {
      this.transObj.transaction.comments = this.commentsWithOverride;
    },
    nonCCTenderAmount() {
      this.creditLoading = true;
      clearTimeout(this.creditTimeoutHolder);
      this.creditTimeoutHolder = setTimeout(() => {
        this.updateCreditAmount(this.nonCCTotal - this.nonCCTenderAmount);
      }, 300);
    },
    ccTotal() {
      this.updateCreditAmount(this.nonCCTotal - this.nonCCTenderAmount);
    },
    async nonCCTotal() {
      let cctotal = parseFloat(this.nonCCTotal);
      const ccFees = await this.getCreditCardFees(cctotal);
      this.creditLoading = false;

      for (let i = 0; i < ccFees.length; i++) {
        //if (ccFees[i].isCreditFee != null) { // include credit card fees
        const feeAmount = parseFloat(ccFees[i].feeAmount);
        if (!isNaN(feeAmount)) {
          // if fee is a number
          cctotal += parseFloat(ccFees[i].feeAmount);
        }
        // }
      }

      this.ccTotal = cctotal;
    },
    showPaymentFrame() {
      if (this.showPaymentFrame) {
        this._.assign(this.card.card, { cardpresent: "no" });
      } else if (
        !this.showPaymentFrame &&
        this._.has(this.card, "card.cardpresent")
      ) {
        this._.unset(this.card, "card.cardpresent");
      }
    }
  },
  methods: {
    ...mapActions([
      "setPaymentFrameMessageHandler",
      "removePaymentFrameMessageHandler",
      "getHubConfiguration"
    ]),
    handleRemainingAmountAfterUniterm() {
      const remainingAmount =
        parseFloat(this.remainingAmount) + this.ccFeeTotal;
      if (
        parseFloat(remainingAmount) < 0 &&
        !parseFloat(this.card.amount) > 0
      ) {
        this.change.amount = parseFloat(remainingAmount);
      }

      this.updateTransactionPayments();

      //setting the address field to null since we are now using single field and dont want anything but address1 set after an edit.
      if (this._.has(this.transObj, "owners")) {
        this.transObj.owners[0].physicalAddress.streetNo = null;
      }

      //check if there is any remaining amount left
      if (this.remainingAmount > 0) {
        this.tenderAmount = this.change.amount;
        return false;
      } else {
        this.doTransactionRequest(this.transObj);
      }

      this.isCharging = false;
    },
    handleScanClose() {
      this.scanModalIsShown = false;
    },
    async handleScan(str) {
      if (str != undefined && str.length > 0) {
        this.$set(this.transObj, "DocumentLocator", {
          Append: true,
          Document: str,
          CountyID: parseInt(this.$store.getters.countyId)
        });
        this.scanModalIsShown = false;
      }
    },
    async cancel() {
      this.clerkCanceled = true;
      this.checkDeviceStatus = false;
      this.customerManualEntry = false;
      this.card.amount = 0;
      this.ccTotal = 0;
      if (this.cardReaderType == "uniterm") {
        this.cardActionMessage = this.cardStatusMap["CANCEL"];

        /** This begins the cancelling process. NOTE** transaction is not
         * necessarily canceled when this endpoint is called. We only know the
         * transaction is canceled when the transaction status is UID_NOT_FOUND
         */

        await this.$hubapp.queryUniterm({
          u_action: "cancel",
          u_id: "42"
        });
      } else {
        this.isCharging = false;
      }
    },
    async paymentFrameSetup() {
      const hmacObj = await this.$api.getHMACValues(location.origin);

      this.hmacFieldString = {
        "data-hmac-hmacsha256": hmacObj.hmacsha256,
        "data-hmac-timestamp": hmacObj.ts,
        "data-hmac-domain": location.origin,
        "data-hmac-sequence": hmacObj.seq,
        "data-hmac-username": hmacObj.user,
        "data-hmac-expdate-format": hmacObj.expDateFormat,
        "data-hmac-include-zip": hmacObj.includeZip,
        "data-hmac-include-street": hmacObj.includeStreet
      };
      this.showPaymentFrame = true;

      function MonetraPaymentForm(iframeElementId, iframeDomain, iframePath) {
        this.iframe = document.getElementById(iframeElementId);
        this.iframeDomain = iframeDomain;
        this.iframeUrl = iframeDomain + iframePath;
        this.paymentSubmittedCallback = null;
      }

      const localPaymentFrameMessageHandler =
        this.setPaymentFrameMessageHandler;

      MonetraPaymentForm.prototype = {
        setPaymentSubmittedCallback: function (callback) {
          this.paymentSubmittedCallback = callback;
        },

        enableSubmitButton() {
          this.iframe.contentWindow.postMessage(
            JSON.stringify({ type: "enableSubmitButton" }),
            this.iframeDomain
          );
        },

        request() {
          /* Using the data- attributes on the iframe element (which contain the
           * hmac values), create a temporary form that will send a POST request to Monetra,
           * requesting the actual payment form
           */
          const iframeAttributes = Array.prototype.slice.call(
            this.iframe.attributes
          );
          const form = this.iframe.contentDocument.createElement("form");
          form.setAttribute("method", "post");
          form.setAttribute("action", this.iframeUrl);
          this.iframe.contentDocument.body.appendChild(form);
          for (let i = 0; i < iframeAttributes.length; i++) {
            const attribute = iframeAttributes[i];
            if (attribute.name.indexOf("data-hmac") !== 0) {
              continue;
            }
            const input = this.iframe.contentDocument.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", attribute.name.replace("data-", ""));
            input.setAttribute("value", attribute.value);
            form.appendChild(input);
          }

          const paymentFrameMessageHandler = function (event) {
            const message = JSON.parse(event.data);
            if (event.origin !== this.iframeDomain) {
              return;
            }
            /* Update the iframe element\'s height based on the height value sent
             * from the iframe
             */
            if (message.type === "height") {
              this.iframe.style.height = message.content + "px";
            }
            /* Once payment is submitted, call the callback function that the customer
             * has provided in their custom javascript
             */
            if (
              message.type === "paymentSubmitted" &&
              this.paymentSubmittedCallback !== null
            ) {
              this.paymentSubmittedCallback(message.content);
            }
          }.bind(this);

          localPaymentFrameMessageHandler(paymentFrameMessageHandler);

          form.submit();
        }
      };

      this.$nextTick(() => {
        const paymentForm = new MonetraPaymentForm(
          "paymentFrame",
          this.$store.state.BaseInfo.config.paymentFrameHost,
          "/PaymentFrame"
        );
        paymentForm.setPaymentSubmittedCallback(response => {
          if (response.code === "AUTH") {
            this.storedTicket = response.ticket;
            this.chargeCard();
          } else {
            if (this.monetraVerbiageWhitelist.includes(response.msoft_code)) {
              this.$store.dispatch("setGlobalAlertState", {
                title: "Error!",
                description: response.verbiage,
                icon: "error"
              });
            } else if (response.code === "DUPL") {
              this.$store.dispatch("setGlobalAlertState", {
                title: "Error!",
                description: "Duplicate Transaction",
                icon: "error"
              });
            } else {
              this.$store.dispatch("setGlobalAlertState", {
                title: "Error!",
                description: "There was a problem processing the card!",
                icon: "error"
              });
            }

            paymentForm.enableSubmitButton();
          }
        });
        paymentForm.request();
      });
    },
    closePaymentFrame() {
      this.removePaymentFrameMessageHandler();
      this.showPaymentFrame = false;
    },
    async updateCreditAmount(ccAmount) {
      this.removeCreditCardFees();

      if (ccAmount > 0) {
        let creditTotal = ccAmount;
        const creditFees = await this.getCreditCardFees(ccAmount);
        this.removeCreditCardFees(); // this must occur after getCreditCardFees resolves to avoid a race condition
        this.creditLoading = false;
        for (let i = 0; i < creditFees.length; i++) {
          creditTotal += creditFees[i].feeAmount;
          this.transObj.transaction.fees.push(creditFees[i]);
        }
        this.creditTotal = creditTotal;
      } else {
        this.creditTotal = 0;
        this.creditLoading = false;
      }
      this.card.amount = this.creditTotal;
    },
    async setupManualCardPayment() {
      this.customerManualEntry = true;
      await this.setHubAppMonetraCreds();

      if (this.cardReaderType === "uniterm" && this.cardReaderHasKeypad) {
        this.card.amount = Math.round(this.creditTotal * 100) / 100;
        this.isCharging = true;
        this.chargeUniterm();
      } else {
        this.paymentFrameSetup();
      }
    },
    async chargeMSR(msg) {
      this.isCharging = false;

      if (msg.Resp.code !== "AUTH") {
        if (this.monetraVerbiageWhitelist.includes(msg.Resp.msoft_code)) {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error!",
            description: msg.Resp.verbiage,
            icon: "error"
          });
        } else if (msg.Resp.code === "DUPL") {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error!",
            description: "Duplicate Transaction",
            icon: "error"
          });
        } else {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error!",
            description: "There was a problem with the card information!",
            icon: "error"
          });
        }
      } else {
        this.cardResponse = msg;

        this.updateTransactionPayments();
        if (parseFloat(this.card.amount) > 0) {
          this.card.card.ticket = this.cardResponse.Resp.ticket;
        }

        //setting the address field to null since we are now using single field and dont want anything but address1 set after an edit.
        if (this._.has(this.transObj, "owners")) {
          this.transObj.owners[0].physicalAddress.streetNo = null;
        }

        //check if there is any remaining amount left
        if (this.remainingAmount > 0) {
          this.tenderAmount = this.remainingAmount;
          return false;
        } else {
          this.doTransactionRequest(this.transObj);
        }
      }
    },
    async chargeUniterm() {
      //getting a ptran number here and checking if the charge has been made before
      let ptranResp;
      try {
        ptranResp = await this.$api.getPTranNo(this.transObj);
      } catch (e) {
        this.isCharging = false;
        console.error(e);
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error!",
          description: "Error retrieving transaciton identifier!",
          icon: "error"
        });
      }

      if (
        ptranResp.batchno == undefined ||
        ptranResp.batchno == 0 ||
        ptranResp.authid == undefined ||
        ptranResp.authid == "" ||
        ptranResp.ttid == undefined ||
        ptranResp.ttid == ""
      ) {
        try {
          const flags = this.customerManualEntry
            ? "DEVICEONLY|AVS|KEY|DELAYRESPONSE"
            : "DEVICEONLY|DELAYRESPONSE";
          this.cardActionMessage = this.cardStatusMap["deviceLoading"];
          this.checkDeviceStatus = true;
          this.startDeviceStatusHandler();
          const vin = this.transObj?.vehicle?.vin;
          const plate =
            this.transObj.registration?.newPlate?.plateNo ||
            this.transObj.registration?.currentPlate?.plateNo ||
            this.transObj?.placard?.controlNo;

          let comment = vin ? vin : "";
          comment += vin && plate ? "/" : "";
          comment += plate ? plate : "";

          let username = this.userObject.username;
          if (!username.includes("@")) {
            const county = this.counties.find(
              county => county.countyID === this.countyId
            );
            username += "@" + county.countyName.toLowerCase();
          }

          const customErrorhandlers = {
            424: () => {
              throw new Error("Transaction timed out, please try again!");
            }
          };

          const transMsg = await this.$hubapp.queryUniterm(
            {
              u_action: "txnrequest",
              action: "Sale",
              u_id: "42",
              nsf: "no",
              amount: parseFloat(this.card.amount).toFixed(2),
              Comments: comment,
              stationid: this.workstationName,
              clerkID: username,
              timeout: 30,
              u_device: this.hubConfiguration.paymentDevice.u_device,
              u_devicetype: this.hubConfiguration.paymentDevice.u_devicetype,
              u_flags: flags,
              duplcheck: true,
              ordernum: ptranResp.ptrannum,
              ptrannum: ptranResp.ptrannum
            },
            customErrorhandlers
          );

          this.checkDeviceStatus = false;

          if (transMsg.Resp.u_errorcode.toLowerCase() === "canceled") {
            throw new UnitermException(
              "Canceled",
              "The Transaction was Canceled by the Customer!"
            );
          }

          if (
            transMsg.Resp.code !== "AUTH" &&
            this.monetraVerbiageWhitelist.includes(transMsg.Resp.msoft_code)
          ) {
            throw new UnitermException("Error", transMsg.Resp.verbiage);
          }

          if (transMsg.Resp.code === "DENY") {
            //todo this is where i will check declined card. for test all cards decline
            if (transMsg.Resp.u_errorcode === "DEVICE_ERROR") {
              throw new UnitermException(
                "Device Error!",
                `Could not connect to ${this.hubConfiguration.paymentDevice.deviceName}`
              );
            } else {
              throw new UnitermException(
                "Declined",
                "The card was declined by the processor!"
              );
            }
          }

          if (transMsg.Resp.code === "DUPL") {
            throw new UnitermException("Error", "Duplicate Transaction.");
          }

          if (
            transMsg.Resp.u_errorcode != undefined &&
            transMsg.Resp.u_errorcode != "SUCCESS" &&
            transMsg.Resp.u_errorcode.length > 0 &&
            transMsg.Resp.verbiage != "APPROVED"
          ) {
            throw new UnitermException(
              "Error",
              "There was a problem with the card information!"
            );
          }

          this.cardResponse = transMsg;

          this.card.card = {
            batchno: parseInt(this.cardResponse.Resp.batch),
            ptrannum: ptranResp.ptrannum,
            ttid: this.cardResponse.Resp.ttid,
            authid: this.cardResponse.Resp.auth,
            account: this.cardResponse.Resp.account
          };
          this.handleRemainingAmountAfterUniterm();
        } catch (error) {
          this.cancel();
          this.isCharging = false;
          this.checkDeviceStatus = false;
          this.customerManualEntry = false;

          if (error.errorCode === "Canceled" && this.clerkCanceled === true) {
            this.clerkCanceled = false;
            return;
          }

          const errorTitle = error.errorCode || "Error";
          const errorMessage =
            error.message ||
            "An error has ocurred. Please re-try the transaction.";
          this.$store.dispatch("setGlobalAlertState", {
            title: errorTitle,
            description: errorMessage,
            icon: "error"
          });
        }
      } else {
        if (this.card.amount <= ptranResp.CustomArgs.amount) {
          if (this.card.amount < ptranResp.CustomArgs.amount) {
            await this.$hubapp.queryMonetra({
              action: "admin",
              admin: "fieldedit",
              ptrannum: ptranResp.ptrannum,
              amount: parseFloat(this.card.amount).toFixed(2)
            });
          }

          this.card.card = {
            batchno: ptranResp.batchno,
            ptrannum: ptranResp.ptrannum,
            ttid: ptranResp.ttid,
            authid: ptranResp.authid
          };

          this.handleRemainingAmountAfterUniterm();
        } else if (this.card.amount > ptranResp.CustomArgs.amount) {
          const response = await this.$hubapp.queryMonetra({
            action: "reversal",
            ptrannum: ptranResp.ptrannum
          });

          if (response.Resp.code !== "AUTH") {
            await this.$hubapp.queryMonetra({
              action: "void",
              ptrannum: ptranResp.ptrannum
            });
          }

          this.chargeUniterm();
        }
      }
    },
    chargeManual() {
      if (parseFloat(this.card.amount) > 0) {
        this.card.card.ticket = this.storedTicket;
      }
      const remainingAmount = this.remainingAmount + this.ccFeeTotal;
      if (
        parseFloat(remainingAmount) < 0 &&
        !parseFloat(this.card.amount) > 0
      ) {
        this.change.amount = parseFloat(remainingAmount);
      }
      this.updateTransactionPayments();

      //setting the address field to null since we are now using single field and dont want anything but address1 set after an edit.
      if (this._.has(this.transObj, "owners")) {
        this.transObj.owners[0].physicalAddress.streetNo = null;
      }

      //check if there is any remaining amount left
      if (this.change.amount > 0) {
        this.tenderAmount = this.change.amount;
        return false;
      } else {
        this.doTransactionRequest(this.transObj);
      }
    },
    async chargeCard() {
      await this.setHubAppMonetraCreds();
      this.transObj.payments = [];
      this.card.amount = parseFloat(this.creditTotal.toFixed(2));
      if (
        this.check.amount > 0 &&
        (this.check.check.checkno == null || this.check.check.checkno == "")
      ) {
        this.$root.$emit(
          "push-alert",
          "Check number is a required field if there is a check amount entered.",
          { color: "error" }
        );
        return false;
      } else if (
        parseFloat(this.card.amount) == 0 ||
        this.card.amount == null
      ) {
        this.cashPay();
        return false;
      } else {
        let total = 0;
        //clean up fees obj from user input
        for (let i = 0; i < this.transObj.transaction.fees.length; i++) {
          this.transObj.transaction.fees[i].feeAmount = parseFloat(
            this.transObj.transaction.fees[i].feeAmount
          );
          total = total + this.transObj.transaction.fees[i].feeAmount;
        }

        if (this.mode === "edit" && this.originalCardAmount > 0) {
          // use existing card info
          this.updateTransactionPayments();
          this.$emit("paymentSubmitted");
          return;
        }
        if (this.storedTicket != "") {
          this.chargeManual();
        } else {
          this.isCharging = true;
          //need to check here if hub is running and if not kill this process.

          /** We may want to look at the user's device configs in the future to determine if we should use uniterm.
           * For now, we're just looking to see if there are EMV devices connected to determine how to process cards.
           */
          if (this.cardReaderType == "uniterm") {
            this.chargeUniterm();
          } else if (this.cardReaderType == "") {
            //error they dont have a card reader type
            this.$store.dispatch("setGlobalAlertState", {
              title: "Error!",
              description:
                "The hub app is not running please start the app or download it.",
              icon: "error"
            });
            return false;
          }
        }
      }
    },
    updateTransactionPayments() {
      this.transObj.payments = [];
      if (this.cash.amount > 0) {
        this.cash.amount = parseFloat(this.cash.amount);
        this.transObj.payments.push(this.cash);
      }
      if (this.check.amount > 0) {
        this.check.amount = parseFloat(this.check.amount);
        this.transObj.payments.push(this.check);
      }
      if (this.card.amount > 0) {
        this.card.amount = parseFloat(this.card.amount);
        this.transObj.payments.push(this.card);
      }
      //todo, figure out why this does not calculate as zero
      if (
        parseFloat(this.change.amount) < 0 &&
        !parseFloat(this.card.amount) > 0
      ) {
        this.transObj.payments.push(this.change);
      }
    },
    cashPay() {
      this.updateTransactionPayments();

      //check if there is any remaining amount left
      if (this.change.amount > 0) {
        this.tenderAmount = this.change.amount;
        return false;
      }
      let total = 0;

      this.removeCreditCardFees();

      for (let i = 0; i < this.transObj.transaction.fees.length; i++) {
        this.transObj.transaction.fees[i].feeAmount = parseFloat(
          this.transObj.transaction.fees[i].feeAmount
        );
        total = total + this.transObj.transaction.fees[i].feeAmount;
      }
      if (this._.has(this.transObj, "owners")) {
        this.transObj.owners[0].physicalAddress.streetNo = null;
      }

      this.doTransactionRequest(this.transObj);
    },
    async doTransactionRequest(dataToSend) {
      // todo> This emit tells the parent History component that the information has been submitted by the user and that the transactionRequest has been updated with changes by this component that occur after the "Check Out" or "Edit" button is clicked. We can eliminate the need for this by making the model update reactively to user input instead of using chargeCard in the future.
      if (this.mode === "edit") {
        this.$emit("paymentSubmitted");
        return;
      }

      this.$root.$emit("setLoading", true);

      //this flag tells the api to save to transaction, true means it will test it, false means it will save.
      dataToSend.transaction.isVerify = false;

      try {
        const errorHandling = {
          400: async error => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Unable to complete transaction due to an error.",
              description: formatErrorResponse(error),
              icon: "error"
            });
            throw formatErrorResponse(error);
          }
        };

        this.transactionIsPending = true;
        let transactionResponse;

        try {
          transactionResponse = await this.createTransaction(
            dataToSend,
            errorHandling
          );
        } catch (error) {
          console.error(error);
        } finally {
          this.transactionIsPending = false;
        }
        this.transactionResponse = transactionResponse;

        /** Put the new title number from /tandr in the transObj.
         * This is how title numbers are assigned.
         */
        if (
          transactionResponse.titleNo != undefined &&
          dataToSend.title != undefined
        ) {
          dataToSend.title.titleNo = transactionResponse.titleNo;
        }

        this.$store.commit("vinHistoryArrayADD", this.transObj);
        if (this.isFeatureEnabled("drafts")) {
          if (this._.has(dataToSend, "vehicle")) {
            const unfinishedDrafts = this.$store.getters.unfinishedDrafts;
            for (let i = 0; i < unfinishedDrafts.length; i++) {
              if (unfinishedDrafts[i].vin == dataToSend.vehicle.vin) {
                this.$store.commit("removeUnfinishedDraft", i);
              }
            }
          }
        }

        const itemsToPrint = this.getItemsToPrint(
          dataToSend,
          this.transactionType,
          transactionResponse
        );
        if (
          this.officeConfig.digitallySign201 &&
          itemsToPrint.some(item => item.doc === "201") &&
          ((await this.topazConnected()) || this.cardReaderHasSignatureSupport)
        ) {
          this.transObj.signature = await this.get201Signature();
        }

        if (this.transactionType == "Undercover Registration") {
          dataToSend.transaction.fees = this.underCoverFees;
          this.transObj = dataToSend;
        }

        this.$store.commit("instaTitlePrintFailure", false);

        await this.printTransaction(
          itemsToPrint,
          dataToSend,
          transactionResponse
        ); // awaiting so that print errors can be handled by checkout component
        this.$root.$emit("push-alert", "Transaction was saved successfully", {
          color: "success"
        });
        if (this.transactionType == "Registration Renewal") {
          this.$router.push({ name: "Home" });
        } else {
          this.transactionIsComplete = true;
          this.$router.push({
            name: "Documentation",
            params: {
              transactionRequest: new TransactionRequest(this.transObj),
              transactionResponse: this.transactionResponse,
              instaTitlePrintFailure: this.instaTitlePrintFailure
            }
          });
        }
      } catch (error) {
        console.error(error);
        this.$root.$emit(
          "push-alert",
          "Unable to complete transaction due to an error.",
          { color: "red" }
        );
        this.$root.$emit("setLoading", false);
      }
    },
    goBackKeepEdits() {
      this.cancel();
      if (
        ["New Placard", "Placard Renewal", "Replace Placard"].includes(
          this.transactionType
        ) &&
        this.editObj?.vehicle === undefined
      ) {
        this.$router.push({
          name: "PlacardTransaction",
          params: {
            placard: ![null, undefined, ""].includes(
              this.transObj.placard.oldControlNo
            )
              ? this.transObj.placard.oldControlNo
              : this.transObj.placard.controlNo,
            isFromBackButton: true
          }
        });
      } else {
        this.show = false;
        this.$router.push({
          name: "Transaction",
          params: {
            vin: this.transObj.vehicle?.vin || this.editObj.vehicle.vin,
            make:
              this.transObj.vehicle?.makeCode || this.editObj.vehicle.makeCode,
            year:
              this.transObj.vehicle?.modelYear ||
              this.editObj.vehicle.modelYear,
            menuIsShown: true,
            isFromBackButton: true
          }
        });
      }
    },
    findFeeIndex(feeGroup) {
      return this.fees.findIndex(fee => fee.feeGroup === feeGroup);
    },
    get201Signature() {
      this.showSignaturePad = true;
      // Since this will take some time we create a promise and pass the resolve function
      // as a sort of callback into the signature pad dialog component, which will then
      // call the resolve function with the signature thus returning and
      // clearing the await
      return new Promise(resolve => {
        this.signatureResolve = resolve;
      });
    },
    topazConnected() {
      if (typeof IsSigWebInstalled !== "function") return;

      if (!IsSigWebInstalled()) return false;

      SetTabletState(1, null, 50);
      if (GetTabletState() !== "0") {
        SetTabletState(0, null);
        return true;
      }
      return false;
    },
    async setHubAppMonetraCreds() {
      await this.$store.dispatch("getMonetraCreds");
      await this.$hubapp.setMonetraCreds({
        user: this.monetraUser,
        password: this.monetraPass,
        monetraHost: this.$store.state.BaseInfo.config.monetraUrl.host.replace(
          "https://",
          ""
        )
      });
    },
    startDeviceStatusHandler() {
      if (this.checkDeviceStatus) {
        this.getDeviceStatus().then(() => {
          window.setTimeout(() => {
            this.startDeviceStatusHandler();
          }, 1000);
        });
      }
    },
    async getDeviceStatus() {
      if (this.cardActionMessage === this.cardStatusMap["CANCEL"]) return;
      const params = {
        u_action: "status",
        u_id: "42"
      };
      const status = await this.$hubapp.queryUniterm(params);
      if (status.Resp !== undefined && status.Resp.u_status !== undefined) {
        const customStatusMessage = this.cardStatusMap[status.Resp.u_status];
        if (customStatusMessage) {
          this.cardActionMessage = customStatusMessage;
        } else {
          this.cardActionMessage = this.cardStatusMap["default"];
        }
      } else {
        this.cardActionMessage = this.cardStatusMap["deviceLoading"];
      }
    }
  },
  computed: {
    ...mapGetters({
      overrideComment: "overrideComment",
      editObj: "editObj",
      installerPath: "installerPath",
      isFeatureEnabled: "isFeatureEnabled",
      officeConfig: "officeConfig",
      pendingTransactionRequest: "pendingTransactionRequest",
      monetraUser: "monetraUser",
      monetraPass: "monetraPass",
      workstationName: "workstationName",
      userObject: "userObject",
      counties: "locations",
      countyId: "countyId",
      hubConfiguration: "hubConfiguration",
      instaTitlePrintFailure: "instaTitlePrintFailure"
    }),
    commentsWithOverride() {
      if (
        ![null, undefined, ""].includes(this.overrideComment) &&
        this.overrideFees === true
      ) {
        return [...this.transactionComments, this.overrideComment];
      }
      return this.transactionComments;
    },
    transactionIsDisabled() {
      return (
        !this.hubAppIsResponsive ||
        this.transactionIsComplete ||
        this.creditLoading
      );
    },
    nonCCTenderAmount() {
      return (
        parseFloat(this.cash.amount || 0) + parseFloat(this.check.amount || 0)
      );
    },
    remainingAmount() {
      let remainingAmount =
        parseFloat(this.nonCCTotal) -
        (parseFloat(this.cash.amount) || 0) -
        (parseFloat(this.check.amount) || 0) -
        (parseFloat(this.card.amount) || 0);

      if (isNaN(remainingAmount)) {
        remainingAmount = parseFloat(this.nonCCTotal);
      }

      //TODO: Remove this.change.amount mutation from computed
      //eslint-disable-next-line
      this.change.amount = remainingAmount;
      return remainingAmount;
    },
    nonCCTotal() {
      let total = 0;

      for (let i = 0; i < this.transObj.transaction.fees.length; i++) {
        if (!this.transObj.transaction.fees[i].isCreditCardFee) {
          // exclude credit card fees
          const feeAmount = parseFloat(
            this.transObj.transaction.fees[i].feeAmount
          );
          if (!isNaN(feeAmount)) {
            // if fee is a number
            total += parseFloat(this.transObj.transaction.fees[i].feeAmount);
          }
        }
      }

      return Math.round(total * 100) / 100;
    }
  },
  destroyed() {
    this.cancel();
    this.removePaymentFrameMessageHandler();
    if (this.SSE !== null) this.SSE.close();
    clearTimeout(this.SSETimeout);
  },
  created() {
    if (this.transObj.transaction.comments === undefined)
      this.transObj.transaction.comments = [];

    this.transactionComments = this._.cloneDeep(
      this.transObj.transaction.comments
    );

    this.transObj.transaction.comments = this.commentsWithOverride;

    //show prompt license credit fee based of config
    if (this.officeConfig.promptForLicenseCreditConfirm && !this.isEditTrans) {
      const licenseCreditFeeIndex = this.fees.findIndex(
        ({ feeName }) => feeName === "License Credit Fee"
      );

      if (licenseCreditFeeIndex !== -1) {
        new Promise(resolve => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Confirm License Credit",
            description:
              "This transaction contains a license credit. Is this correct?",
            icon: "warning",
            actions: [
              {
                text: "Yes",
                color: "primary",
                handler: () => {
                  this.$store.dispatch("hideGlobalAlert");
                  resolve();
                }
              },
              {
                text: "No",
                handler: () => {
                  this.transObj.transaction.fees[
                    licenseCreditFeeIndex
                  ].feeAmount = 0;
                  this.$store.dispatch("hideGlobalAlert");
                  resolve();
                }
              }
            ]
          });
        });
      }
    }
  },
  async mounted() {
    if (
      !this._.has(this.transObj, "vehicle") &&
      !this._.has(this.transObj, "placard") &&
      !this._.has(this.transObj, "dealerPlateTransactions")
    ) {
      this.$router.push({ name: "Home" });
      return;
    }

    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      this.$store.state.BaseInfo.config.paymentFrameHost +
        "/PaymentFrame/PaymentFrame.js"
    );
    document.head.appendChild(recaptchaScript);

    //doing some clean up on fees to make them useful by changing the optional fees to 0 and adding a flag to turn them on and off the total can be accurate.
    //when this is saved the flag will need to be removed and total set to whatever user value was picked

    if (this.transactionType == "Undercover Registration") {
      this.transObj = new TransactionRequest(this.transObj);
      this.transObj.transaction.convertToUndercover();
      this.underCoverFees = this.transObj.transaction.getUndercoverPrintFees();
    }

    await this.updateCreditAmount(this.nonCCTotal); //uses that total to get credit fees
    for (let i = 0; i < this.fees.length; i++) {
      this.addAmountOptions(this.fees[i], this.mode === "edit");
    }
    this.creditTotal = this.ccTotal;

    const arr = {};
    for (let i = 0; i < this.transObj.transaction.fees.length; i++) {
      if (arr[this.transObj.transaction.fees[i].feeGroup] === undefined) {
        arr[this.transObj.transaction.fees[i].feeGroup] =
          this.transObj.transaction.fees[i].feeAmount;
      } else {
        arr[this.transObj.transaction.fees[i].feeGroup] =
          parseFloat(arr[this.transObj.transaction.fees[i].feeGroup]) +
          parseFloat(this.transObj.transaction.fees[i].feeAmount);
      }
    }
    this.tenderAmount = this.change.amount;

    let cctotal = parseFloat(this.nonCCTotal);

    for (let i = 0; i < this.transObj.transaction.fees.length; i++) {
      if (this.transObj.transaction.fees[i].isCreditCardFee) {
        // include credit card fees
        const feeAmount = parseFloat(
          this.transObj.transaction.fees[i].feeAmount
        );
        if (!isNaN(feeAmount)) {
          // if fee is a number
          cctotal += parseFloat(this.transObj.transaction.fees[i].feeAmount);
        }
      }
    }

    this.ccTotal = cctotal;

    this.$nextTick(() => {
      this.$root.$emit("setLoading", false);
    });

    /** This is necessary for MSR charging and Uniterm transaction statuses. */
    if (this.hubConfiguration === undefined) {
      await this.getHubConfiguration();
    }
    const device = this.hubConfiguration.paymentDevice;

    if (device === undefined || ["MSR", "None"].includes(device.type)) {
      this.cardReaderType = "hid";
    } else {
      this.cardReaderType = "uniterm";
      this.cardReaderHasKeypad =
        device?.functionality.includes("PIN") !== undefined
          ? device.functionality.includes("PIN")
          : false;
      this.cardReaderHasSignatureSupport =
        device?.functionality.includes("SIGNATURE") !== undefined
          ? device.functionality.includes("SIGNATURE")
          : false;
    }
    this.hubAppIsResponsiveCheck();
  },
  async beforeRouteLeave(to, from, next) {
    if (this.pendingTransactionRequest) {
      if (to.name !== "Transaction") {
        await this.$api.queueUnAssign(
          this.pendingTransactionRequest.transactionID
        );
      }
    }
    next();
  }
};
</script>
<style scoped lang="scss">
.pad {
  padding: 25px;
}
.scan-close-modal {
  text-align: center;
  padding: 25px;
}
.main-cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main-cards .v-card {
  margin: 10px;
}

.floating-buttons {
  position: fixed;
  left: 40px;
  bottom: 20px;
}
.editFees {
  max-width: 1000px;
}
.dialogClose {
  position: fixed;
  top: 20px;
  right: 40px;
}

select {
  margin: 0;
}

.paymentDiv {
  width: 100%;
  margin-left: 15%;
  margin-bottom: 15px;
  text-align: left;
}
.paymentLabel {
  width: 150px;
  min-width: 110px;
  font-weight: bold;
  display: inline-block;
}
.paymentInput {
  display: inline-block;
  width: 30%;
  min-width: 150px;
}
.paymentCopyAmount {
  min-width: auto;
  text-transform: none;
  margin: 0 0 5px 0;
}
.mainBox {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}

.red {
  border-color: red;
}

#chargeWrapper {
  background: rgba(95, 95, 95, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9999;
}

.pulsate {
  animation: pulsate 2s ease-out;
  -webkit-animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.5;
}

@keyframes pulsate {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.field-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-table {
  margin-left: 50px;
}

.payment-table td:first-child {
  text-align: left;
}

.credsPrompt {
  h2 {
    text-align: center;
    padding: 5px;
  }
}

.fees-card {
  padding-bottom: 50px;
}

.fixedBottom {
  right: 50%;
  bottom: 50px;
  transform: translateX(50%);
  z-index: 210;
  width: 100%;
}

#override-cancel {
  transform: translateX(3px) translateY(30px);
}

.manual-card-option {
  margin-bottom: 10px;
}

::v-deep .finishEditFees {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -40%);
}

::v-deep .v-dialog {
  margin-top: 4rem;
  width: 98%;
  justify-content: "center";
}
</style>
