<template>
  <v-dialog v-model="show" width="550" persistent>
    <v-card width="550" id="container">
      <template v-if="isTopaz">
        <div>
          <canvas name="canvas" id="canvas" height="100" width="500"></canvas>
        </div>
        <div>
          <v-btn @click="clearTopaz()" class="mr-5">Clear</v-btn>
          <v-btn @click="signTopaz()">Sign</v-btn>
        </div>
      </template>
      <template v-show="!isTopaz">
        <div v-if="awaitingSignature">
          <v-card>
            <v-card-title>
              {{ waitingText }}
            </v-card-title>
          </v-card>
        </div>
        <div v-show="!awaitingSignature">
          <div id="dwtcontrolContainer"></div>
          <div v-if="doneSigning" class="d-flex justify-center">
            <v-btn @click="rejectUniterm()" class="mr-5" color="error">
              Reject
            </v-btn>
            <v-btn @click="signUniterm()" color="success">Sign</v-btn>
          </div>
        </div>
      </template>
      <v-card-actions v-if="!doneSigning">
        <v-btn @click="cancel()" color="error">No Signature</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*global Dynamsoft, IsSigWebInstalled */
import { mapGetters } from "vuex";

export default {
  name: "SignaturePad",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    resolve: {
      required: true
    },
    cardReaderType: {
      type: String,
      required: true
    },
    ptrannum: {
      type: String
    }
  },
  data: () => ({
    timer: null,
    unitermResponse: null,
    awaitingSignature: false,
    isSigWebInstalled: false,
    doneSigning: false,
    DWObject: null,
    signature: null,
    waitingText: null
  }),
  watch: {
    show(newVal) {
      if (!newVal) {
        return;
      }
      if (this.isTopaz) {
        this.$nextTick(() => {
          window.SetDisplayXSize(500);
          window.SetDisplayYSize(100);
          window.SetTabletState(0, this.timer);
          window.SetJustifyMode(0);
          this.clearTopaz();
          const ctx = document.getElementById("canvas").getContext("2d");
          if (this.timer === null) {
            this.timer = window.SetTabletState(1, ctx, 50);
          } else {
            window.SetTabletState(0, this.timer);
            this.timer = null;
            this.timer = window.SetTabletState(1, ctx, 50);
          }
        });
      } else {
        this.$nextTick(() => {
          Dynamsoft.DWT.Load();
          Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", this.onReady);
        });
      }
    }
  },
  computed: {
    ...mapGetters(["hubConfiguration"]),
    isTopaz() {
      return this.isSigWebInstalled && this.cardReaderType !== "uniterm";
    }
  },
  methods: {
    clearTopaz() {
      window.ClearTablet();
    },
    signTopaz() {
      window.SetTabletState(0, this.timer);
      window.SetImageXSize(500);
      window.SetImageYSize(100);
      window.GetSigImageB64(img => {
        this.resolve(img);
      });
    },
    rejectUniterm() {
      this.signature = null;
      this.DWObject.RemoveImage(0);
      this.getSignature(true);
    },
    signUniterm() {
      this.resolve(this.signature);
    },
    async getSignature(forceNew = false) {
      this.doneSigning = false;
      let signature;
      if (forceNew || !this.ptrannum) {
        signature = await this.getUnitermSignature();
      } else {
        signature = await this.getMonetraSignature(this.ptrannum);
      }
      this.doneSigning = true;
      if (!signature) return;
      this.convertAndDisplaySignature(signature);
    },
    async getUnitermSignature() {
      this.waitingText = "Waiting For Customer Signature";
      this.awaitingSignature = true;
      const obj = {
        u_action: "reqsignature",
        u_device: this.hubConfiguration.paymentDevice.u_device,
        u_devicetype: this.hubConfiguration.paymentDevice.u_devicetype,
        u_id: "42",
        u_flags: "DEVICEONLY|DELAYRESPONSE"
      };
      try {
        const response = await this.$hubapp.queryUniterm(obj);
        this.awaitingSignature = false;
        if (response.Resp.u_errorcode === "PENDING_TRAN") {
          await this.cancel(false);
          return "";
        }
        if (response.Resp.u_errorcode === "CANCELED") {
          return undefined;
        }
        if (response.Resp.u_errorcode === "DEVICE_ERROR") {
          await new Promise(res => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "Error!",
              description: "Uniterm Device not found!",
              icon: "error",
              actions: [
                {
                  text: "Retry Connection",
                  handler: () => {
                    this.$store.dispatch("hideGlobalAlert");
                    res();
                    this.rejectUniterm();
                  },
                  color: "primary"
                },
                {
                  text: "No Signature",
                  handler: () => {
                    res();
                    this.cancel();
                    this.$store.dispatch("hideGlobalAlert");
                  },
                  color: "red"
                }
              ]
            });
          });
        }
        if (response.Resp.u_errorcode === "SUCCESS") {
          return response.Resp.u_signature;
        }
      } catch (error) {
        this.awaitingSignature = false;
        await this.cancel(false);
        return "";
      }
    },
    async getMonetraSignature(ptrannum) {
      this.waitingText = "Loading Customer Signature";
      this.awaitingSignature = true;
      const obj = {
        action: "admin",
        admin: "getimages",
        ptrannum
      };
      const response = await this.$hubapp.queryMonetra(obj);
      this.awaitingSignature = false;
      if (response.Resp.code !== "SUCCESS") {
        return;
      }
      // DataBlock is essentially a CSV
      const results = response.Resp.DataBlock.split("\n");
      // The only results we got were the type headers so no actual results
      if (results.length === 1) {
        return this.getUnitermSignature();
      }
      // Grab the first real result, split it by headers and return the 'image' (b64 signature)
      return results[1].split(",")[6];
    },
    onReady() {
      this.DWObject = Dynamsoft.DWT.GetWebTwain("dwtcontrolContainer");
      this.DWObject.Width = 500;
      this.DWObject.Height = 100;
      this.getSignature();
    },
    convertAndDisplaySignature(b64Signature) {
      this.DWObject.LoadImageFromBase64Binary(b64Signature, 2);
      this.DWObject.ChangeImageSize(0, 500, 100, 5);
      this.DWObject.ConvertToBase64(
        [0],
        3,
        base64Result => {
          const length = base64Result.getLength();
          this.signature = base64Result.getData(0, length);
        },
        (...error) => {
          console.error(error);
        }
      );
    },
    async cancel(resolve = true) {
      if (this.isTopaz) {
        this.resolve(undefined);
        return;
      }
      const obj = {
        u_action: "cancel",
        u_id: "42",
        u_flags: "DEVICEONLY|DELAYRESPONSE"
      };
      await this.$hubapp.queryUniterm(obj);
      if (resolve) {
        this.resolve(undefined);
      }
    }
  },
  created() {
    if (typeof IsSigWebInstalled !== "function") {
      this.isSigWebInstalled = false;
    } else {
      this.isSigWebInstalled = IsSigWebInstalled();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style>
#canvas {
  border: 2px solid black;
  margin-bottom: 2%;
}

#container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
}
</style>
