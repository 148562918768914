<template>
  <div>
    <v-dialog v-model="show">
      <v-card class="scanner-window">
        <div class="closeModalButton">
          <v-btn @click="close" icon color="red">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="dialogMain">
          <imageScanner
            :showScanControls="showScanControls"
            v-if="show"
            :existingImage="existingImage"
            @submitClicked="
              str => {
                $emit('submitClicked', str);
              }
            "
          ></imageScanner>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import imageScanner from "@/components/nonPageComponents/ImageScanner";
export default {
  components: { imageScanner },
  props: {
    existingImage: String,
    show: Boolean,
    showScanControls: { type: Boolean, default: true, required: false }
  },
  data() {
    return {
      showModal: this.show,
      showScan: false,
      showCloseScan: false
    };
  },
  methods: {
    close() {
      if (!this.showScanControls) {
        this.showModal = false;
        this.showCloseScan = false;
      } else {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Are You Sure?",
          minWidth: "350px",
          description: "You will lose all unsaved progress.",
          icon: "warning",
          actions: [
            {
              text: "Close",
              color: "error",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
                this.showModal = false;
              }
            },
            {
              text: "Cancel",
              color: "primary",
              handler: () => {
                this.$store.dispatch("hideGlobalAlert");
              }
            }
          ]
        });
      }
    }
  },
  watch: {
    show() {
      if (this.show != this.showModal) {
        this.showModal = this.show;
      }
    },
    showModal() {
      if (this.showModal) {
        this.$emit("show");
      } else {
        this.$emit("close");
      }
    }
  }
};
</script>
<style scoped>
.scanner-window {
  height: 90vh;
}
.dialogMain {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
