<template>
  <div class="history">
    <div>
      <div v-show="showMain">
        <div id="history_app" class="panel">
          <div>Click on a result to see further details.</div>

          <div class="bootstrap">
            <b-table
              id="tableOrder"
              :sticky-header="stickyTableHeight"
              empty-text="No Results"
              :fields="tableFields"
              head-variant="light"
              sort-icon-left
              :hover="true"
              :sort-by="'statusCode'"
              :sort-desc="true"
              responsive="sm"
              @row-clicked="item => getHistoryDetails(item.requestBody)"
              :items="history"
              show-empty
            >
              <template v-slot:empty>
                {{ emptyText }}
              </template>
              <template v-slot:cell(image)="data">
                <v-tooltip
                  bottom
                  v-if="data.item.documentLocatorNo != undefined"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      data-testid="imageIcon"
                      icon
                      v-on="on"
                      @click="
                        $event.stopPropagation();
                        getImage(data.item);
                      "
                    >
                      <v-icon>collections</v-icon>
                    </v-btn>
                  </template>
                  <span>Open the Image Viewer</span>
                </v-tooltip>
              </template>

              <template v-slot:cell(scan)="data">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      data-testid="scanIcon"
                      icon
                      v-on="on"
                      @click="
                        $event.stopPropagation();
                        scan(data.item);
                      "
                    >
                      <v-icon>scanner</v-icon>
                    </v-btn>
                  </template>
                  <span>Open the Image Scanner</span>
                </v-tooltip>
              </template>
              <template v-slot:cell(comments)="data">
                <center
                  v-if="_.has(data.item, 'requestBody.transaction.comments')"
                >
                  <v-icon>message</v-icon>
                </center>
              </template>
              <template v-slot:cell(transactionDate)="data">
                {{ data.item.transactionDate.substr(0, 10) }}
                {{ data.item.transactionDate.substr(11, 8) }}
              </template>
              <template v-slot:cell(statusCode)="data">
                {{ transactionStatuses[data.item.statusCode] }}
              </template>
              <template v-slot:cell(plate)="data">
                {{ data.item.plateNo }} / {{ data.item.plateClassCode }} /
                {{ data.item.issueYear }}
              </template>
              <template v-slot:cell(county)="data">
                {{ counties[data.item.countyID] }}
              </template>
              <template v-slot:cell(action)="data">
                <div class="actions">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        data-testid="editIcon"
                        v-on="on"
                        icon
                        v-if="
                          data.item.transactionID ==
                            mostRecentTrans.transactionID &&
                          data.item.VTRSTransactionID !== undefined &&
                          data.item.transactionTypeCode != 'CLZ' &&
                          $store.getters.countyId == data.item.countyID &&
                          transactionStatuses[data.item.statusCode] != 'VOID'
                        "
                        @click="
                          $event.stopPropagation();
                          editTrans(
                            data.item.requestBody,
                            data.item.transactionTypeCode,
                            data.item.VTRSTransactionID,
                            data.item.transactionID
                          );
                        "
                      >
                        <v-icon color="blue">edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit This Record</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        data-testid="voidRecordIcon"
                        icon
                        v-on="on"
                        v-if="
                          data.item.transactionID ==
                            mostRecentTrans.transactionID &&
                          data.item.VTRSTransactionID !== undefined &&
                          data.item.transactionTypeCode != 'CLZ' &&
                          $store.getters.countyId == data.item.countyID &&
                          transactionStatuses[data.item.statusCode] != 'VOID'
                        "
                        @click="
                          $event.stopPropagation();
                          voidDialogIsShown = true;
                          voidSelected = data.item;
                        "
                      >
                        <v-icon color="red">close</v-icon>
                      </v-btn>
                    </template>
                    <span>Void This Record</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        data-testid="voidTitleIcon"
                        icon
                        v-on="on"
                        @click="
                          voidTitleDialogIsShown = true;
                          voidSelected = data.item.requestBody;
                        "
                        v-if="
                          data.item.transactionID ===
                            mostRecentTrans.transactionID &&
                          _.has(detailsObj, 'title.titleNo') &&
                          detailsObj.title.titleNo != '' &&
                          data.item.requestBody !== null &&
                          data.item.requestBody.titleIssue
                        "
                      >
                        <v-icon color="red">mdi-file-remove</v-icon>
                      </v-btn>
                    </template>
                    <span>Void This Title</span>
                  </v-tooltip>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <div v-if="showDetails" class="panel">
        <displayVehicleData
          data-testid="detailsPanel"
          :processObj="processObj"
          :transactionType="transactionType"
          :readOnly="true"
          @overrideFees="overrideFees = $event"
          @overrideIsValid="overrideIsValid = $event"
        ></displayVehicleData>
        <v-btn raised @click="backToHistoryTable">Back</v-btn>
      </div>

      <div v-if="showAudit">
        <h2>Audit</h2>
        <hr />
        <button style="margin-right: 80%" v-on:click="backToHistoryTable">
          Back to Results
        </button>
        <div style="padding-left: 50px; padding-right: 50px">
          User ID: <b>{{ historyAuditArray.userID }}</b
          ><br />
          <div
            v-for="(value, index) in historyAuditArray.comments"
            :key="index"
          >
            Comment {{ index + 1 }}: <b>{{ value }}</b
            ><br />
          </div>
          <table
            class="subTable star"
            style="text-align: left"
            v-if="
              historyAuditArray != undefined &&
              historyAuditArray.diff != undefined
            "
          >
            <tr v-for="(value, index) in historyAuditArray.diff" :key="index">
              <td class="bold">{{ value.property.replace("Vehicle.", "") }}</td>
              <td>
                Changed From
                <b v-if="value.old == '' || value.old == undefined">Blank</b
                ><b v-else>{{ value.old }}</b> to <b>{{ value.new }}</b>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div v-if="showEdit" class="panel">
        <displayVehicleData
          data-testid="editPanel"
          v-show="editPage === 'Transaction'"
          :mostRecentTrans="mostRecentTrans"
          :processObj="processObj"
          :isEditTrans="true"
          :transactionType="transactionType"
          @nameCodeChange="nameCode = $event"
          :isNewOwner="false"
          :showIdScan="false"
          @overrideFees="overrideFees = $event"
          @overrideIsValid="overrideIsValid = $event"
        />
        <check-out
          v-show="editPage === 'CheckOut'"
          ref="checkout"
          :isEditTrans="true"
          :transactionRequest="processObj"
          mode="edit"
          @paymentSubmitted="saveTransaction"
        />
        <div class="fixedBottomRight">
          <v-switch
            v-if="
              processObj.title &&
              [null, undefined, ''].includes(processObj.title.titleNo)
            "
            v-model="processObj.title.assignTitleNo"
            label="Assign Title Number"
          />
          <v-btn
            v-if="editPage === 'Transaction'"
            raised
            color="green"
            @click="processPayment"
            :disabled="!transactionIsValid"
          >
            Save Edit Transaction
          </v-btn>
        </div>
        <div class="fixedBottomLeft">
          <v-btn color="red" raised @click="backToHistoryTable">
            <span class="white-text">Back</span>
          </v-btn>
          <v-btn
            raised
            color="blue"
            @click="
              editPage = editPage === 'Transaction' ? 'CheckOut' : 'Transaction'
            "
          >
            {{
              editPage === "Transaction"
                ? "Fees and Payment"
                : "Vehicle Information"
            }}
          </v-btn>
        </div>
      </div>
    </div>

    <scanModal
      :show="showScan"
      @show="showScan = true"
      @close="hideScanModal()"
      :existingImage="storedImage"
      @submitClicked="saveImages"
    />

    <v-dialog v-model="voidDialogIsShown" max-width="50%">
      <v-card data-testid="voidConfirmCard">
        <div class="voidModal">
          <h2>Are you sure?</h2>
          <p>
            This will void the transaction.
            <br />

            <v-text-field
              autofocus
              v-model="voidComment"
              :error="voidComment.length < 1"
              :error-messages="voidComment.length < 1 ? ['Required'] : []"
              label="Comment"
            />
          </p>
          <v-btn
            :disabled="loading"
            raised
            color="red"
            @click="
              voidComment = '';
              voidSelected = {};
              voidDialogIsShown = false;
            "
          >
            <span class="white-text">Cancel</span>
          </v-btn>
          &nbsp;
          <v-btn
            :loading="loading"
            :disabled="loading || voidComment === ''"
            raised
            color="green"
            @click="voidTrans"
          >
            <span class="white-text">Save</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="voidTitleDialogIsShown" max-width="50%">
      <v-card data-testid="voidTitleConfirmCard">
        <div class="voidModal">
          <h2>Are you sure?</h2>
          <p>
            This will void the title.
            <br />

            <v-text-field
              autofocus
              v-model="voidComment"
              :error="voidComment.length < 1"
              :error-messages="voidComment.length < 1 ? ['Required'] : []"
              label="Comment"
            />
          </p>
          <v-btn
            :disabled="loading"
            raised
            color="red"
            @click="
              voidComment = '';
              voidTitleDialogIsShown = false;
            "
          >
            <span class="white-text">Cancel</span>
          </v-btn>
          &nbsp;
          <v-btn
            :loading="loading"
            :disabled="loading || voidComment === ''"
            raised
            color="green"
            @click="voidTitle(voidSelected)"
          >
            <span class="white-text">Save</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import displayVehicleData from "@/components/nonPageComponents/DisplayVehicleData";
import vehicleFunctions from "@/mixins/vehicleFunctions";
import scanModal from "@/components/nonPageComponents/scanModal";
import checkOut from "@/components/CheckOut";
import transaction from "@/mixins/transaction.mixin.js";
import fees from "@/mixins/fees.mixin.js";
import date from "@/mixins/date.mixin";
import { mapGetters } from "vuex";

const transactionTypes = require("@/assets/jsonScaffolds/transactionTypes.json");

export default {
  name: "history",
  components: {
    displayVehicleData,
    scanModal,
    checkOut
  },
  mixins: [transaction, fees, date, vehicleFunctions],
  props: {
    vin: {
      type: String,
      default: ""
    },
    controlNo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      history: [],
      loading: false,
      voidDialogIsShown: false,
      voidTitleDialogIsShown: false,
      voidComment: "",
      voidSelected: {},
      historyDetails: {},
      historyAuditArray: {},
      transactionEditArray: {},
      titleCode:
        this.$store.getters.titleCodes[this.$store.getters.titleCodeID],
      processPermission: this.$store.getters.editPermissions[0],
      nameCode: "",
      issueYearArray: "",
      comment: "",
      processObj: null,
      showOwner: false,
      showMailing: false,
      images: [],
      showScan: false,
      selectedImageTrans: "",
      storedImage: "",
      showDetails: false,
      showMain: true,
      showEdit: false,
      showAudit: false,
      showCloseScan: false,
      stickyTableHeight: "calc(-400px + 100vh)",
      mostRecentTrans: {},
      tableFields: [
        {
          key: "image",
          sortable: true
        },
        {
          key: "scan",
          sortable: true
        },
        {
          key: "comments",
          sortable: true
        },
        {
          key: "transactionDate",
          sortable: true,
          sortDesc: false
        },
        {
          key: "plate",
          sortable: true
        },
        {
          key: "transactionTypeCode",
          label: "Type",
          sortable: true
        },
        {
          key: "titleNo",
          label: "Title",
          sortable: true
        },
        {
          key: "customerName",
          sortable: true
        },
        {
          key: "statusCode",
          label: "Status",
          sortable: false
        },
        {
          key: "county",
          sortable: true
        },
        {
          key: "action"
        }
      ],
      overrideFees: false,
      overrideIsValid: true,
      transactionType: "",
      emptyText: "", // text to display when the history table is empty
      editPage: "Transaction" // the page to display when editing a transaction ("Transaction" or "CheckOut")
    };
  },
  created() {
    this.loadHistory();
  },
  methods: {
    async saveImages(str) {
      if (
        this.selectedImageTrans == undefined ||
        this.selectedImageTrans == ""
      ) {
        this.$root.$emit("push-alert", "No details for this record.", {
          color: "error"
        });
        return;
      }
      const dataToSend = {
        transactionID: parseInt(this.selectedImageTrans),
        createdUserID: parseInt(this.$store.getters.userObject.id),
        statusCode: "A",
        document: str
      };
      this.$root.$emit("setLoading", true);
      try {
        await this.$api.submitDocuments(
          dataToSend,
          this.detailsObj.placard?.controlNo
        );
        await this.loadHistory();
      } finally {
        this.hideScanModal();
        this.$root.$emit("setLoading", false);
      }
    },
    scan(value) {
      if (value.documentLocatorNo) {
        return this.getImage(value);
      }
      this.selectedImageTrans = value.transactionID;
      this.storedImage = "";
      this.showScan = true;
    },
    hideScanModal() {
      this.showScan = false;
      this.selectedImageTrans = "";
      this.backToHistoryTable();
    },
    addComment() {
      this.processObj.transaction.comments.push("");
      setTimeout(() => {
        document
          .getElementById(
            "comment" + this.processObj.transaction.comments.length
          )
          .focus();
      }, 50);
    },
    sortLienholders(lienholders) {
      const sorted = lienholders.slice(0);
      sorted.sort(function (a, b) {
        const x = a.lienPrecedenceNo;
        const y = b.lienPrecedenceNo;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return sorted;
    },
    classSelect() {
      if (!this._.has(this.processObj, "registration.newPlate")) {
        return;
      }

      let platecheck = "";
      platecheck = this.processObj.registration.newPlate.class;
      if (platecheck === "") {
        this.issueYearArray = "";
        this.processObj.registration.currentPlate.class = "";
      } else {
        for (let i = 0; i < this.$store.getters.plateClasses.length; i++) {
          if (
            this.$store.getters.plateClasses[i].plateClassCode == platecheck
          ) {
            this.issueYearArray = this.$store.getters.plateClasses[
              i
            ].plateDates.map(plateDate => plateDate.issueYear);
          }
        }
      }
      setTimeout(() => {
        const options = document.getElementById("issueYear").options;
        for (let i = 0; i < options.length; i++) {
          if (
            options[i].value ===
            this.processObj.registration.currentPlate.issueYear
          ) {
            document.getElementById("issueYear").selectedIndex = i + 1;
            break;
          }
        }

        document.getElementById("issueYear").selectedIndex = "1";
      }, 50);
    },
    async getHistoryDetails(processObj) {
      if (processObj === null) {
        this.$root.$emit("push-alert", "No details for this record.", {
          color: "error"
        });
      }

      this.processObj = this.reformatTandrObjectforTransactionScreen(
        this._.cloneDeep(processObj)
      );
      this.showMain = false;
      this.showDetails = true;
      this.$root.$emit("setLoading", false);
    },
    processPayment() {
      this.$refs.checkout.chargeCard();
    },
    async saveTransaction() {
      // todo> this can be removed when a fee class is used to manage prop types
      if (this._.has(this.processObj, "transaction.fees")) {
        this.processObj.transaction.fees.forEach(
          fee => (fee.feeAmount = parseFloat(fee.feeAmount))
        );
      }

      if (this.processObj.title?.assignTitleNo)
        this.processObj.transaction.statusCode = "1";

      //format the object for tandr
      this.processObj.maintenance = this.maintenance;
      this.processObj = await this.formatForTandR(this.processObj);
      this.removeEmptyComments(this.processObj);

      this.processObj.transaction.isVerify = true;
      delete this.processObj.documentLocator;

      if (this._.has(this.processObj, "owners")) {
        // todo> this can be added to Owner.constructor if we got that route
        for (let i = 0; i < this.processObj.owners.length; i++) {
          if (
            this._.has(
              this.processObj.owners[i],
              "businessCustomer.licenseStatusDate"
            )
          ) {
            delete this.processObj.owners[i].businessCustomer.licenseStatusDate;
          }
        }
      }

      this.$root.$emit("setLoading", true);
      try {
        await this.editTransaction(this.processObj); // verify
        this.processObj.transaction.isVerify = false;
        await this.editTransaction(this.processObj);
      } catch (e) {
        this.$root.$emit("setLoading", false);
        this.inquiryRunning = false;
        return false;
      }

      if (this.processObj.placard === undefined) await this.loadHistory();
      this.$emit("editSucceeded");

      this.$root.$emit("push-alert", "Transaction successfully updated.", {
        color: "success"
      });

      const vin = this._.has(this.processObj, "vehicle.vin")
        ? this.processObj.vehicle.vin
        : "";
      this.$store.commit("titleNo", "");
      this.$store.commit("vin", vin);
      this.$store.commit("vinLast8", "");
      this.$store.commit("plateNo", "");
      this.$store.commit("plateClass", "");
      this.$store.commit("plateClassIssueYear", "");
      this.$store.commit("makeCode", "");
      this.$store.commit("modelCode", "");
      this.$store.commit("name", "");
      this.$store.commit("modelYear", "");
      this.$store.commit("streetAddress", "");
      this.$store.commit("city", "");
      this.$store.commit("state", "");
      this.$store.commit("zip", "");
      this.$store.commit("streetNumber", "");

      this.$store.commit("maintenance", {});

      let resultsData;
      try {
        if (this.processObj.placard !== undefined) {
          resultsData = await this.$api.placardInquiry(
            this.processObj.placard.controlNo
          );
        } else {
          resultsData = await this.$api.inquirySearch({ vin, page: 0 });
        }
      } catch (e) {
        this.inquiryRunning = false;
        return false;
      } finally {
        this.$root.$emit("setLoading", false);
      }
      this.$store.commit("resultsArray", resultsData);
      if (resultsData === null) {
        //no results coming from general inquiry.
        this.$root.$emit("push-alert", "There was a problem with the server", {
          color: "error"
        });
        this.inquiryRunning = false;
        return false;
      } else {
        this.showDetails = false;
        if (this.processObj.placard !== undefined) {
          this.$router.push({
            name: "PlacardDetails",
            params: { placard: this.processObj.placard.controlNo }
          });
        } else this.$router.push({ name: "Results" });
      }
    },
    async getImage(value) {
      this.storedImage = "";
      const response = await this.$api.getImage(value.documentLocatorNo);
      this.storedImage = response.document;
      this.selectedImageTrans = value.transactionID;
      this.showScan = true;
      this.showMain = false;
    },
    backToHistoryTable() {
      this.showMain = true;
      this.showDetails = false;
      this.showAudit = false;
      this.showEdit = false;
      window.scrollTo(0, 0);
    },
    async loadHistory() {
      this.history = [];
      this.emptyText = "Loading...";

      try {
        if (this.controlNo !== "") {
          this.history = await this.$api.plateSearch(this.controlNo, true);
        } else {
          this.history = await this.$api.vinSearch(this.vin, true);
        }
      } catch (e) {
        console.error(e);
        return false;
      } finally {
        this.emptyText = "No transactions found.";
      }

      this.history.forEach(transaction => {
        if(transaction.transactionJSON){
          transaction.requestBody = Object.freeze(
          JSON.parse(transaction.transactionJSON)
          );
        }
        delete transaction.transactionJSON;
      });
      this.mostRecentTrans = this.history[0]; // by requesting with newest=true we can assume the 0th element is the most recent
    },
    async voidTitle(transactionRequest) {
      const transactionRequestClone = this._.cloneDeep(transactionRequest);

      if (
        !transactionRequestClone.transaction.comments ||
        !Array.isArray(transactionRequestClone.transaction.comments)
      ) {
        transactionRequestClone.transaction.comments = [];
      }
      transactionRequestClone.transaction.comments.push(this.voidComment);
      transactionRequestClone.transaction.statusCode = this._.invert(
        this.transactionStatuses
      )["INCOMPLETE"];
      transactionRequestClone.title.statusID = parseInt(
        this._.invert(this.titleCodes)["INVALID"]
      );

      try {
        this.loading = true;
        await this.$api.updateTitleStatus(transactionRequestClone);
        this.$root.$emit("push-alert", "The title was voided.", {
          color: "success"
        });
        this.loadHistory();
        this.$emit("title-voided");
      } catch (e) {
        this.$root.$emit(
          "push-alert",
          "There was a problem voiding the title.",
          { color: "error" }
        );
        console.error(e);
      } finally {
        this.voidSelected = {};
        this.voidComment = "";
        this.loading = false;
        this.voidTitleDialogIsShown = false;
      }
    },
    async voidTrans() {
      if (this.voidComment == "" || this.voidComment == undefined) {
        return false;
      }
      let isPlacard = false;
      const voidDataObj = {
        transaction: {
          transactionID: this.voidSelected.VTRSTransactionID,
          transactionTimestamp: this.getCurrentTimestamp(),
          invoiceNo: this.invoiceNo,
          transactionType: "CLZ",
          comments: [this.voidComment]
        }
      };

      if (this.voidSelected.transactionTypeCode === "PT") {
        isPlacard = true;
        voidDataObj.transaction.countyID = parseInt(
          this.$store.getters.countyId
        );
      } else {
        voidDataObj.vehicle = {
          vin: this.voidSelected.requestBody.vehicle.vin
        };
      }
      try {
        this.loading = true;
        await this.$api.undoTransaction(
          this.voidSelected.VTRSTransactionID,
          voidDataObj,
          isPlacard
        );
        this.$root.$emit("push-alert", "The transation was voided.", {
          color: "success"
        });
        this.loadHistory();
        this.backToHistoryTable();
      } catch (e) {
        console.error(e);
        this.$root.$emit(
          "push-alert",
          "There was a problem voiding this transaction.",
          { color: "error" }
        );
      } finally {
        this.voidComment = "";
        this.voidSelected = {};
        this.loading = false;
        this.voidDialogIsShown = false;
        if (this.history.length === 0) {
          this.$router.push({ name: "Home" });
        }
      }
    },
    async editTrans(transactionObj, transactionType) {
      this.processObj = this.reformatTandrObjectforTransactionScreen(
        this._.cloneDeep(transactionObj)
      );
      this.$store.commit("transactionType", transactionTypes[transactionType]);
      this.$set(this.processObj, "tempComments", []);
      if (this._.has(this, "processObj.transaction.fees"))
        this.processObj.transaction.fees.forEach(fee =>
          this.addAmountOptions(fee, true)
        );
      this.showMain = false;
      this.showEdit = true;
    }
  },
  watch: {
    showScan() {
      this.$emit("scanModalToggle", this.showScan);
    }
  },
  computed: {
    ...mapGetters({
      detailsObj: "processObj",
      counties: "counties",
      imagePer: "imagePermissions",
      vehicleUse: "vehicleUse",
      odometerBrands: "odometerBrands",
      plateClassArray: "plateClasses",
      allTitleBrands: "allTitleBrands",
      countiesArray: "counties",
      titleCodes: "titleCodes",
      transactionStatuses: "transactionStatuses",
      makesArray: "makes",
      vehicleTypesArray: "vehicleTypes",
      colorsArray: "vehicleColors",
      bodyTypesArray: "bodyTypes",
      fuelArray: "fuelTypes",
      toolTipDelay: "toolTipDelay",
      invoiceNo: "invoiceNo",
      maintenance: "maintenance"
    }),
    transactionIsValid() {
      return this.overrideIsValid;
    }
  }
};
</script>

<style scoped>
.voidModal {
  text-align: center;
  padding: 25px;
}

.history {
  padding-top: 20px;
  height: 100%;
  overflow-y: auto;
}
.actions {
  display: inline;
  white-space: nowrap;
}

.green {
  background-color: green !important;
}

.fixedTop {
  position: fixed;
  top: 20px;
}
</style>
