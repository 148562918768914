var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history"},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMain),expression:"showMain"}]},[_c('div',{staticClass:"panel",attrs:{"id":"history_app"}},[_c('div',[_vm._v("Click on a result to see further details.")]),_c('div',{staticClass:"bootstrap"},[_c('b-table',{attrs:{"id":"tableOrder","sticky-header":_vm.stickyTableHeight,"empty-text":"No Results","fields":_vm.tableFields,"head-variant":"light","sort-icon-left":"","hover":true,"sort-by":'statusCode',"sort-desc":true,"responsive":"sm","items":_vm.history,"show-empty":""},on:{"row-clicked":function (item) { return _vm.getHistoryDetails(item.requestBody); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.emptyText)+" ")]},proxy:true},{key:"cell(image)",fn:function(data){return [(data.item.documentLocatorNo != undefined)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"data-testid":"imageIcon","icon":""},on:{"click":function($event){$event.stopPropagation();
                      _vm.getImage(data.item);}}},on),[_c('v-icon',[_vm._v("collections")])],1)]}}],null,true)},[_c('span',[_vm._v("Open the Image Viewer")])]):_vm._e()]}},{key:"cell(scan)",fn:function(data){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"data-testid":"scanIcon","icon":""},on:{"click":function($event){$event.stopPropagation();
                      _vm.scan(data.item);}}},on),[_c('v-icon',[_vm._v("scanner")])],1)]}}],null,true)},[_c('span',[_vm._v("Open the Image Scanner")])])]}},{key:"cell(comments)",fn:function(data){return [(_vm._.has(data.item, 'requestBody.transaction.comments'))?_c('center',[_c('v-icon',[_vm._v("message")])],1):_vm._e()]}},{key:"cell(transactionDate)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.transactionDate.substr(0, 10))+" "+_vm._s(data.item.transactionDate.substr(11, 8))+" ")]}},{key:"cell(statusCode)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.transactionStatuses[data.item.statusCode])+" ")]}},{key:"cell(plate)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.plateNo)+" / "+_vm._s(data.item.plateClassCode)+" / "+_vm._s(data.item.issueYear)+" ")]}},{key:"cell(county)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.counties[data.item.countyID])+" ")]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [(
                        data.item.transactionID ==
                          _vm.mostRecentTrans.transactionID &&
                        data.item.VTRSTransactionID !== undefined &&
                        data.item.transactionTypeCode != 'CLZ' &&
                        _vm.$store.getters.countyId == data.item.countyID &&
                        _vm.transactionStatuses[data.item.statusCode] != 'VOID'
                      )?_c('v-btn',_vm._g({attrs:{"data-testid":"editIcon","icon":""},on:{"click":function($event){$event.stopPropagation();
                        _vm.editTrans(
                          data.item.requestBody,
                          data.item.transactionTypeCode,
                          data.item.VTRSTransactionID,
                          data.item.transactionID
                        );}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("edit")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit This Record")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(
                        data.item.transactionID ==
                          _vm.mostRecentTrans.transactionID &&
                        data.item.VTRSTransactionID !== undefined &&
                        data.item.transactionTypeCode != 'CLZ' &&
                        _vm.$store.getters.countyId == data.item.countyID &&
                        _vm.transactionStatuses[data.item.statusCode] != 'VOID'
                      )?_c('v-btn',_vm._g({attrs:{"data-testid":"voidRecordIcon","icon":""},on:{"click":function($event){$event.stopPropagation();
                        _vm.voidDialogIsShown = true;
                        _vm.voidSelected = data.item;}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Void This Record")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [(
                        data.item.transactionID ===
                          _vm.mostRecentTrans.transactionID &&
                        _vm._.has(_vm.detailsObj, 'title.titleNo') &&
                        _vm.detailsObj.title.titleNo != '' &&
                        data.item.requestBody !== null &&
                        data.item.requestBody.titleIssue
                      )?_c('v-btn',_vm._g({attrs:{"data-testid":"voidTitleIcon","icon":""},on:{"click":function($event){_vm.voidTitleDialogIsShown = true;
                        _vm.voidSelected = data.item.requestBody;}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-remove")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Void This Title")])])],1)]}}])})],1)])]),(_vm.showDetails)?_c('div',{staticClass:"panel"},[_c('displayVehicleData',{attrs:{"data-testid":"detailsPanel","processObj":_vm.processObj,"transactionType":_vm.transactionType,"readOnly":true},on:{"overrideFees":function($event){_vm.overrideFees = $event},"overrideIsValid":function($event){_vm.overrideIsValid = $event}}}),_c('v-btn',{attrs:{"raised":""},on:{"click":_vm.backToHistoryTable}},[_vm._v("Back")])],1):_vm._e(),(_vm.showAudit)?_c('div',[_c('h2',[_vm._v("Audit")]),_c('hr'),_c('button',{staticStyle:{"margin-right":"80%"},on:{"click":_vm.backToHistoryTable}},[_vm._v(" Back to Results ")]),_c('div',{staticStyle:{"padding-left":"50px","padding-right":"50px"}},[_vm._v(" User ID: "),_c('b',[_vm._v(_vm._s(_vm.historyAuditArray.userID))]),_c('br'),_vm._l((_vm.historyAuditArray.comments),function(value,index){return _c('div',{key:index},[_vm._v(" Comment "+_vm._s(index + 1)+": "),_c('b',[_vm._v(_vm._s(value))]),_c('br')])}),(
            _vm.historyAuditArray != undefined &&
            _vm.historyAuditArray.diff != undefined
          )?_c('table',{staticClass:"subTable star",staticStyle:{"text-align":"left"}},_vm._l((_vm.historyAuditArray.diff),function(value,index){return _c('tr',{key:index},[_c('td',{staticClass:"bold"},[_vm._v(_vm._s(value.property.replace("Vehicle.", "")))]),_c('td',[_vm._v(" Changed From "),(value.old == '' || value.old == undefined)?_c('b',[_vm._v("Blank")]):_c('b',[_vm._v(_vm._s(value.old))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(value.new))])])])}),0):_vm._e()],2)]):_vm._e(),(_vm.showEdit)?_c('div',{staticClass:"panel"},[_c('displayVehicleData',{directives:[{name:"show",rawName:"v-show",value:(_vm.editPage === 'Transaction'),expression:"editPage === 'Transaction'"}],attrs:{"data-testid":"editPanel","mostRecentTrans":_vm.mostRecentTrans,"processObj":_vm.processObj,"isEditTrans":true,"transactionType":_vm.transactionType,"isNewOwner":false,"showIdScan":false},on:{"nameCodeChange":function($event){_vm.nameCode = $event},"overrideFees":function($event){_vm.overrideFees = $event},"overrideIsValid":function($event){_vm.overrideIsValid = $event}}}),_c('check-out',{directives:[{name:"show",rawName:"v-show",value:(_vm.editPage === 'CheckOut'),expression:"editPage === 'CheckOut'"}],ref:"checkout",attrs:{"isEditTrans":true,"transactionRequest":_vm.processObj,"mode":"edit"},on:{"paymentSubmitted":_vm.saveTransaction}}),_c('div',{staticClass:"fixedBottomRight"},[(
            _vm.processObj.title &&
            [null, undefined, ''].includes(_vm.processObj.title.titleNo)
          )?_c('v-switch',{attrs:{"label":"Assign Title Number"},model:{value:(_vm.processObj.title.assignTitleNo),callback:function ($$v) {_vm.$set(_vm.processObj.title, "assignTitleNo", $$v)},expression:"processObj.title.assignTitleNo"}}):_vm._e(),(_vm.editPage === 'Transaction')?_c('v-btn',{attrs:{"raised":"","color":"green","disabled":!_vm.transactionIsValid},on:{"click":_vm.processPayment}},[_vm._v(" Save Edit Transaction ")]):_vm._e()],1),_c('div',{staticClass:"fixedBottomLeft"},[_c('v-btn',{attrs:{"color":"red","raised":""},on:{"click":_vm.backToHistoryTable}},[_c('span',{staticClass:"white-text"},[_vm._v("Back")])]),_c('v-btn',{attrs:{"raised":"","color":"blue"},on:{"click":function($event){_vm.editPage = _vm.editPage === 'Transaction' ? 'CheckOut' : 'Transaction'}}},[_vm._v(" "+_vm._s(_vm.editPage === "Transaction" ? "Fees and Payment" : "Vehicle Information")+" ")])],1)],1):_vm._e()]),_c('scanModal',{attrs:{"show":_vm.showScan,"existingImage":_vm.storedImage},on:{"show":function($event){_vm.showScan = true},"close":function($event){return _vm.hideScanModal()},"submitClicked":_vm.saveImages}}),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.voidDialogIsShown),callback:function ($$v) {_vm.voidDialogIsShown=$$v},expression:"voidDialogIsShown"}},[_c('v-card',{attrs:{"data-testid":"voidConfirmCard"}},[_c('div',{staticClass:"voidModal"},[_c('h2',[_vm._v("Are you sure?")]),_c('p',[_vm._v(" This will void the transaction. "),_c('br'),_c('v-text-field',{attrs:{"autofocus":"","error":_vm.voidComment.length < 1,"error-messages":_vm.voidComment.length < 1 ? ['Required'] : [],"label":"Comment"},model:{value:(_vm.voidComment),callback:function ($$v) {_vm.voidComment=$$v},expression:"voidComment"}})],1),_c('v-btn',{attrs:{"disabled":_vm.loading,"raised":"","color":"red"},on:{"click":function($event){_vm.voidComment = '';
            _vm.voidSelected = {};
            _vm.voidDialogIsShown = false;}}},[_c('span',{staticClass:"white-text"},[_vm._v("Cancel")])]),_vm._v("   "),_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading || _vm.voidComment === '',"raised":"","color":"green"},on:{"click":_vm.voidTrans}},[_c('span',{staticClass:"white-text"},[_vm._v("Save")])])],1)])],1),_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.voidTitleDialogIsShown),callback:function ($$v) {_vm.voidTitleDialogIsShown=$$v},expression:"voidTitleDialogIsShown"}},[_c('v-card',{attrs:{"data-testid":"voidTitleConfirmCard"}},[_c('div',{staticClass:"voidModal"},[_c('h2',[_vm._v("Are you sure?")]),_c('p',[_vm._v(" This will void the title. "),_c('br'),_c('v-text-field',{attrs:{"autofocus":"","error":_vm.voidComment.length < 1,"error-messages":_vm.voidComment.length < 1 ? ['Required'] : [],"label":"Comment"},model:{value:(_vm.voidComment),callback:function ($$v) {_vm.voidComment=$$v},expression:"voidComment"}})],1),_c('v-btn',{attrs:{"disabled":_vm.loading,"raised":"","color":"red"},on:{"click":function($event){_vm.voidComment = '';
            _vm.voidTitleDialogIsShown = false;}}},[_c('span',{staticClass:"white-text"},[_vm._v("Cancel")])]),_vm._v("   "),_c('v-btn',{attrs:{"loading":_vm.loading,"disabled":_vm.loading || _vm.voidComment === '',"raised":"","color":"green"},on:{"click":function($event){return _vm.voidTitle(_vm.voidSelected)}}},[_c('span',{staticClass:"white-text"},[_vm._v("Save")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }